import { Button } from "devextreme-react";
import DataGrid, { Column } from "devextreme-react/data-grid";
import Form, { Item } from "devextreme-react/form";
import CustomStore from "devextreme/data/custom_store";
import { ClickEvent } from "devextreme/ui/button";
import dxDataGrid from "devextreme/ui/data_grid";
import dxForm from "devextreme/ui/form";
import notify from "devextreme/ui/notify";
import { FunctionComponent, useState } from "react";
import { useToken } from "../../../hooks/useToken";
import { Company } from "../../../models/company";
import { RegisterUserAsync } from "../../../services/http-service";

export interface RegisterUserProps {
    userCompanies: CustomStore
}

export const RegisterUser: FunctionComponent<RegisterUserProps> = (props) => {
    let [form, setForm] = useState<dxForm>();
    let [companiesData, setCompaniesData] = useState<dxDataGrid>();
    const token = useToken();

    const onSubmit = async (_: ClickEvent) => {
        const formData = form?.option("formData");

        const values = (await companiesData?.getSelectedRowsData()) as Company[];

        const result = await RegisterUserAsync(token.getToken()!, { email: formData.email, isAdmin: formData.isAdmin, companies: values, hasAccessToEvaluations: formData.hasAccessToEvaluations })

        if (result.ok) {
            notify("User was registered successfully.", "success", 2500);
            form?.resetOption("formData");
            companiesData?.clearSelection();
            return await result.json();
        } else {
            result.json().then(d => notify(d.message, "error", 2500));
        }
    };

    return (
        <>
            <Button icon="save"
                text="Save" onClick={onSubmit} />
            <Form
                onInitialized={(f) => {
                    setForm(f.component);
                }}
            >
                <Item dataField="email"></Item>
                <Item dataField="isAdmin" editorType="dxCheckBox"></Item>
                <Item dataField="hasAccessToEvaluations" editorType="dxCheckBox"></Item>
                <Item dataField="companies">
                    <DataGrid selection={{ mode: "multiple" }}
                        dataSource={props.userCompanies}
                        onInitialized={(f) => {
                            setCompaniesData(f.component);
                        }}
                    >
                        <Column dataField="companyName" />
                    </DataGrid>
                </Item>
            </Form>
        </>
    )
}