import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './Nav.css';

export interface NavLinkProps {
  path: string;
  name: string;
}

export interface NavButtonProps {
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  text: string;
}

export interface NavProps {
  children?: JSX.Element | JSX.Element[] | string | string[];
}

export const NavLink: FunctionComponent<NavLinkProps> = (props) => {
  return <Link to={props.path}>{props.name}</Link>
}

export const NavAnchor: FunctionComponent<NavLinkProps> = (props) => {
  return <a href={props.path} target='_blank' rel='noreferrer'>{props.name}</a>
}

export const NavButton: FunctionComponent<NavButtonProps> = (props) => {
  return <a href='/'
    onClick={(e) => { e.preventDefault(); props.onClick(e); return false; }}>
    {props.text}
  </a>
}

const Nav: FunctionComponent<NavProps> = (props) => {
  return (
    <div className='nav'>
      {props.children}
    </div>
  );
}

export default Nav;
