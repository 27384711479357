import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { FunctionComponent, useState } from 'react';
import { useToken } from '../../hooks/useToken';
import CompaniesDropdown from '../../components/companies-dropdown/CompaniesDropdown';

import DataGrid, { Column } from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import './CampaignData.css'
import notify from 'devextreme/ui/notify';
import { GetCampaignsAsync, GetCompanyPositionsAsync, UpdateCampaignAsync } from '../../services/http-service';
import { Company, Position } from '../../models/company';

const CampaignData: FunctionComponent = () => {
  let token = useToken();

  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);

  // const selectedCompany = useContext(SelectedCompanyCtx);
  // console.log(selectedCompany);
  // For server pagination
  // const dataStore = new ODataStore({
  //   url: 'http://developers02.mellongroup.com:7079/api/campaigns',
  //   key: 'id',
  //   beforeSend: async (o) => {
  //     o.params['companyid'] = selectedCompanyId;
  //     o.headers["Authorization"] = token;
  //   }
  // });

  const getCompanyPositionsStore = (selectedCompany: Company | undefined) => new CustomStore({
    loadMode: "raw",
    key: "id",
    load: (o) => {
      if (!token.getToken()) {
        return Promise.reject();
      }

      if (!selectedCompany) return Promise.resolve([]);

      return GetCompanyPositionsAsync(token.getToken()!, selectedCompany?.id)
        .then(d => d as (Position | null)[])
        .then(d => { d.unshift(null); return d; })
        .catch(err => {
          console.error(err);
          notify("There was an error while retrieving the positions", "error", 2500)
          return Promise.reject();
        })
    }
  });

  // For client pagination
  const getDataStore = (selectedCompany: Company | undefined) => new CustomStore({
    loadMode: "processed",
    key: "id",
    load: (o) => {
      if (!token.getToken()) {
        return Promise.reject();
      }

      if (!selectedCompany) return Promise.resolve([]);

      return GetCampaignsAsync(selectedCompany.id, token.getToken()!)
        .catch(err => {
          console.error(err);
          notify("There was an error while fetching the campaigns", "error", 2500)
          return [];
        });
    },
    update: (key, values) => {
      if (!token.getToken()) {
        return Promise.reject();
      }

      if (values.wfpositionId === null)
        values.wfpositionId = 0
      if (values.wfpositionId === null)
        values.wfUnitId = 0

      return UpdateCampaignAsync(token.getToken()!, { id: key, wfPositionId: values.wfpositionId, wfUnitId: values.wfunitId })
        .then(async r => {
          if (r.ok) {
            notify("Campaigns have been updated successfully.", "success", 2500)
          } else {
            const res = await r.json()
            console.error(res);
            notify(res.message, "error", 2500)
          }
        })
        .catch(err => {
          console.error(err);
          notify("There was an error while updating the campaign", "error", 2500)
        })
    },
  });

  return (
    < div className="camp-table">
      <AuthenticatedTemplate >
        <CompaniesDropdown onSelectedCompanyChanged={(c) => setSelectedCompany(c)} />
        <DataGrid
          id='gridContainer'
          showBorders={true}
          dataSource={token && selectedCompany ? getDataStore(selectedCompany) : undefined}
          pager={{ allowedPageSizes: [10, 20, 50], visible: true, displayMode: "adaptive", showPageSizeSelector: true }}
          editing={{ allowUpdating: true, mode: "batch" }}
          filterRow={{ visible: true }}
          repaintChangesOnly={true}
        >
          <Column dataField="instance.instanceName" caption="Instance Name" allowEditing={false} />
          <Column dataField="altcampaignFullName" caption="Full Name" allowEditing={false} />
          <Column dataField="altcampaignShortName" caption="Short Name" allowEditing={false} />
          <Column dataField="altcampaignId" caption="Campaign Id" allowEditing={false} />
          <Column dataField="wfunitId" caption="Unit Id" dataType="number" />
          <Column dataField="wfpositionId" caption="Position Id" dataType="number" lookup={{
            dataSource: getCompanyPositionsStore(selectedCompany),
            displayExpr: (p: Position) => `${p ? (p?.positionName ?? "Unnamed") + ' (' + (p?.positionId) + ')' : "None"}`,
            valueExpr: (p: Position) => p?.positionId || null
          }} />
        </DataGrid>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        Please sign-in to see this page.
      </UnauthenticatedTemplate>
    </div>
  );
}

export default CampaignData;
