import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { FunctionComponent, useState } from 'react';
import { useToken } from '../../../hooks/useToken';
import CompaniesDropdown from '../../../components/companies-dropdown/CompaniesDropdown';

import DataGrid, { Column } from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { AddPositionToCompanyAsync, GetCompanyPositionsAsync, UpdatePositionNameAsync } from '../../../services/http-service';
import { Company, Position } from '../../../models/company';

const CompanyPositions: FunctionComponent = () => {
    let token = useToken();

    const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);

    const getCompanyPositionsStore = (selectedCompany: Company | undefined) => new CustomStore({
        loadMode: "raw",
        key: "positionId",
        load: (o) => {
            if (!token.getToken()) {
                return Promise.reject();
            }

            if (!selectedCompany) return Promise.resolve([]);

            return GetCompanyPositionsAsync(token.getToken()!, selectedCompany?.id)
                .then(d => d as (Position | null)[])
                .catch(err => {
                    console.error(err);
                    notify("There was an error while retrieving the positions", "error", 2500)
                    return Promise.reject();
                })
        },
        insert: async (v) => {
            if (!token.getToken()) return;
            if (!selectedCompany) return;

            const r = await AddPositionToCompanyAsync(token.getToken()!, v, selectedCompany?.id);

            if (r.ok) {
                notify("Position added", "success");
            } else {
                const err = await r.json();
                notify(`Error adding the position: ${err.message}`, "error");
            }
        },
        update: async (k, v) => {
            if (!token.getToken()) return;
            if (!selectedCompany) return;

            const r = await UpdatePositionNameAsync(token.getToken()!, { positionId: k, positionName: v.positionName }, selectedCompany?.id);

            if (r.ok) {
                notify("Position updated", "success");
            } else {
                const err = await r.json();
                notify(`Error updating the position: ${err.message}`, "error");
            }
        }
    });

    return (
        < div className="camp-table">
            <AuthenticatedTemplate >
                <CompaniesDropdown onSelectedCompanyChanged={(c) => setSelectedCompany(c)} />
                <DataGrid
                    id='gridContainer'
                    showBorders={true}
                    dataSource={token && selectedCompany ? getCompanyPositionsStore(selectedCompany) : undefined}
                    pager={{ allowedPageSizes: [10, 20, 50], visible: true, displayMode: "adaptive", showPageSizeSelector: true }}
                    editing={{ allowAdding: true, allowUpdating: true, mode: "batch" }}
                    filterRow={{ visible: true }}
                    repaintChangesOnly={true}
                >
                    <Column dataField="positionId" />
                    <Column dataField="positionName" />
                </DataGrid>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                Please sign-in to see this page.
            </UnauthenticatedTemplate>
        </div>
    );
}

export default CompanyPositions;
