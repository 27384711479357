import { FunctionComponent, useEffect, useState } from 'react';
import './App.css';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Button } from 'devextreme-react';
import Nav, { NavAnchor, NavButton, NavLink } from './components/nav/Nav';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import CampaignData from './pages/campaign-data/CampaignData';
import { useSilentLogin } from './hooks/useSilentLogin';
import IsAdmin from './components/is-admin/IsAdmin';
import TransactionHistory from './pages/transaction-history/TransactionHistory';
import { Users } from './pages/admin/users/Users';

import themes from 'devextreme/ui/themes'
import Employees from './pages/employees/Employees';
import CompanyPositions from './pages/admin/positions/Positions';
import SkillProfileData from './pages/skill-profile-data/SkillProfileData';

type AppProps = {
  pca: IPublicClientApplication
};

const App: FunctionComponent<AppProps> = ({ pca }: AppProps) => {
  useSilentLogin(pca); //  try to login silently if possible

  const handleLogin = () => {
    pca.loginPopup({
      scopes: ["User.Read"]
    });
  }

  let [themeName, setThemeName] = useState("Dark");

  const handleLogout = () => {
    pca.logoutPopup({
      idTokenHint: ""
    });
    sessionStorage.clear();
  }

  useEffect(() => {
    const prefTheme = localStorage.getItem("__pref_theme");
    if (window?.matchMedia('(prefers-color-scheme: dark)').matches || prefTheme === 'dark') {
      themes.current("generic.dark")
      setThemeName('Light');
    } else {
      themes.current("generic.softblue")
      setThemeName('Dark');
    }
  }, [])


  const updateTheme = () => {
    if (themes.current().includes('dark')) {
      themes.current("generic.softblue");
      setThemeName('Dark');
      localStorage.setItem("__pref_theme", "light")
    } else {
      themes.current("generic.dark");
      setThemeName('Light');
      localStorage.setItem("__pref_theme", "dark")
    }
  };

  return (
    <MsalProvider instance={pca}>
      <div className="App">
        <UnauthenticatedTemplate>
          <h1 className='welcome-text'>Welcome to the WorkForce Application</h1>
          <h3 className='welcome-text'>Login to continue</h3>
          <Button text='LOGIN' type="default" onClick={handleLogin} stylingMode="contained" className='login-button'></Button>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <BrowserRouter>
            <Nav>
              <NavLink path='/' name='Home' />
              <NavLink path='/campaign-data' name='Campaign Data' />
              <NavLink path='/skill-profile-data' name='Skill Profile Data' />
              <NavLink path='/transactions' name='Transaction History' />
              <NavLink path='/employees' name='Employees' />

              <IsAdmin is={<NavLink path='/users' name='Users' />} />
              <IsAdmin is={<NavLink path='/positions' name='Positions' />} />
              <div style={{ float: "right", margin: "auto", marginRight: "0" }}>
                <NavAnchor path='https://cdn.az.mellongroup.com/mellon/WFMManual.pdf' name='Manual' />
                <NavButton
                  onClick={(_) => { updateTheme() }}
                  text={themeName}></NavButton>
                <NavButton onClick={(_) => { handleLogout() }} text="Logout"></NavButton>
              </div>
            </Nav>
            <Routes>
              <Route path="/" element={<h1 className='welcome-text'>Welcome to the WorkForce Application</h1>} />
              <Route path="/campaign-data" element={<CampaignData />} />
              <Route path="/skill-profile-data" element={<SkillProfileData />} />
              <Route path='/transactions' element={<TransactionHistory />} />
              <Route path='/employees' element={<Employees />} />
              <Route path='/users' element={<IsAdmin is={<Users />} />} />
              <Route path='/positions' element={<IsAdmin is={<CompanyPositions />} />} />
            </Routes>
          </BrowserRouter>
        </AuthenticatedTemplate>
      </div>
    </MsalProvider >
  );
}

export default App;
