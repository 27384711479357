import Chart, { CommonSeriesSettings, Legend, Series, Title, Tooltip, ValueAxis } from "devextreme-react/chart";
import Popup from "devextreme-react/popup";
import { useEffect, useState } from "react";
import { useToken } from "../../hooks/useToken";
import Notify from 'devextreme/ui/notify'
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import { DownloadFileAsync, GetCompanyPositionsAsync } from "../../services/http-service";

interface TransactionDataChartProps {
    isVisible: boolean,
    filename?: string,
    selectedCompanyId?: number,
    onClose: (e: CloseEvent) => void
}

const COLOR_POOL = [
    "#FE4365", "#FC9D9A", "#F9CDAD", "#C8C8A9", "#83AF9B", "#ECD078", "#D95B43", "#C02942", "#542437", "#53777A",
    "#556270", "#4ECDC4", "#C7F464", "#FF6B6B", "#C44D58", "#774F38", "#E08E79", "#F1D4AF", "#ECE5CE", "#C5E0DC",
    "#E8DDCB", "#CDB380", "#036564", "#033649", "#031634", "#490A3D", "#BD1550", "#E97F02", "#F8CA00", "#8A9B0F",
    "#594F4F", "#547980", "#45ADA8", "#9DE0AD", "#E5FCC2", "#00A0B0", "#6A4A3C", "#CC333F", "#EB6841", "#EDC951",
    "#E94E77", "#D68189", "#C6A49A", "#C6E5D9", "#F4EAD5", "#D9CEB2", "#948C75", "#D5DED9", "#7A6A53", "#99B2B7",
    "#CBE86B", "#F2E9E1", "#1C140D", "#CBE86B", "#EFFFCD", "#DCE9BE", "#555152", "#2E2633", "#99173C",
    "#3FB8AF", "#7FC7AF", "#DAD8A7", "#FF9E9D", "#FF3D7F", "#343838", "#005F6B", "#008C9E", "#00B4CC", "#00DFFC",
    "#413E4A", "#73626E", "#B38184", "#F0B49E", "#F7E4BE", "#99B898", "#FECEA8", "#FF847C", "#E84A5F", "#2A363B",
    "#FF4E50", "#FC913A", "#F9D423", "#EDE574", "#E1F5C4", "#554236", "#F77825", "#D3CE3D", "#F1EFA5", "#60B99A",
    "#351330", "#424254", "#64908A", "#E8CAA4", "#CC2A41", "#00A8C6", "#40C0CB", "#F9F2E7", "#AEE239", "#8FBE00",
    "#FF4242", "#F4FAD2", "#D4EE5E", "#E1EDB9", "#F0F2EB", "#655643", "#80BCA3", "#F6F7BD", "#E6AC27", "#BF4D28",
    "#8C2318", "#5E8C6A", "#88A65E", "#BFB35A", "#F2C45A", "#FAD089", "#FF9C5B", "#F5634A", "#ED303C", "#3B8183",
    "#BCBDAC", "#CFBE27", "#F27435", "#F02475", "#3B2D38", "#D1E751", "#4DBCE9", "#26ADE4",
    "#FF9900", "#424242", "#E9E9E9", "#BCBCBC", "#3299BB", "#5D4157", "#838689", "#A8CABA", "#CAD7B2", "#EBE3AA",
    "#5E412F", "#FCEBB6", "#78C0A8", "#F07818", "#F0A830", "#EEE6AB", "#C5BC8E", "#696758", "#45484B", "#36393B",
    "#1B676B", "#519548", "#88C425", "#BEF202", "#EAFDE6", "#F8B195", "#F67280", "#C06C84", "#6C5B7B", "#355C7D",
    "#452632", "#91204D", "#E4844A", "#E8BF56", "#E2F7CE", "#F04155", "#FF823A", "#F2F26F", "#FFF7BD", "#95CFB7",
    "#F0D8A8", "#3D1C00", "#86B8B1", "#F2D694", "#FA2A00", "#2A044A", "#0B2E59", "#0D6759", "#7AB317", "#A0C55F",
    "#67917A", "#170409", "#B8AF03", "#CCBF82", "#E33258", "#B9D7D9", "#668284", "#2A2829", "#493736", "#7B3B3B",
    "#BBBB88", "#CCC68D", "#EEDD99", "#EEC290", "#EEAA88", "#A3A948", "#EDB92E", "#F85931", "#CE1836", "#009989",
    "#E8D5B7", "#0E2430", "#FC3A51", "#F5B349", "#E8D5B9", "#B3CC57", "#ECF081", "#FFBE40", "#EF746F", "#AB3E5B",
    "#AB526B", "#BCA297", "#C5CEAE", "#F0E2A4", "#F4EBC3", "#607848", "#789048", "#C0D860", "#F0F0D8", "#604848"
];

export const TransactionDataChart = ({ isVisible, filename, selectedCompanyId, onClose }: TransactionDataChartProps) => {
    let token = useToken();

    let [chartPopupVisible, setChartPopupVisible] = useState<boolean>(isVisible);
    let [chartName, setChartName] = useState<string>("");

    let [positionColors, setPositionColors] = useState<Map<number, string> | null>(null);
    let [positionNames, setPositionNames] = useState<Map<number, string> | null>(null);

    useEffect(() => {
        if (!selectedCompanyId) return;
        GetCompanyPositionsAsync(token.getToken()!, selectedCompanyId)
            .then(d => {
                let availableColors = [...COLOR_POOL];
                let map = new Map<number, string>();
                let map_names = new Map<number, string>();
                d.forEach((el) => {
                    map.set(el.positionId, availableColors.splice(0, 1)[0]);
                    map_names.set(el.positionId, el.positionName);
                });
                setPositionColors(map);
                setPositionNames(map_names);
            })
            .catch(err => {
                console.error(err);
                notify("There was an error while retrieving the positions", "error", 2500)
            });
    }, [selectedCompanyId]);

    const dataStore = new CustomStore({
        loadMode: "processed",
        load: async (o) => {
            if (!token.getToken()) {
                return Promise.reject();
            }

            if (!filename) return [];
            let r = await DownloadFileAsync(token.getToken()!, filename);

            let data = await r.json();

            if (!r.ok) {
                Notify({
                    message: `${data.message
                        }`,
                    type: 'error',
                    displayTime: 2500,
                });
                return [];
            }

            let transactionDate = new Date(data.TransactionDataDate);
            setChartName(`Call data for ${transactionDate.getDate().toString().padStart(2, '0')}-${(transactionDate.getMonth() + 1).toString().padStart(2, '0')}-${transactionDate.getFullYear()}`);

            const regEx = /(\d{4}).(\d{2}).(\d{2}).(\d{2}).(\d{2})/;
            let finalData: any[] = data.TransactionData.map((d: any) => {
                let dateArray = regEx.exec(d.Timestamp)?.map(d => Number.parseInt(d));
                if (!dateArray) throw Error("Unknown error");
                const date = new Date(dateArray[1], dateArray[2] - 1, dateArray[3], dateArray[4], dateArray[5], 0);
                return { timestamp: date, count: d.Value, position: d["Position-Id"] };
            });
            if (isVisible)
                setChartPopupVisible(true);
            return finalData;
        }
    });

    return (
        <Popup visible={chartPopupVisible}
            onVisibleChange={(v) => {
                if (!v) {
                    onClose(new CloseEvent('close'));
                    setChartPopupVisible(false);
                }
            }}>
            <Chart
                title={chartName}
                dataSource={dataStore}
                legend={{ visible: false }}
                customizePoint={component => {
                    return { color: positionColors?.get(component.data.position) };
                }}
            >
                <CommonSeriesSettings
                    type="stackedbar"
                />
                <Series
                    name={"Call Count"}
                    argumentField="timestamp"
                    valueField="count"
                    minBarSize={15}
                    tagField="position"
                />
                < ValueAxis position="left">
                    <Title text="Number of Calls" />
                </ValueAxis>
                <Tooltip enabled={true} location="edge" customizeTooltip={(e: any) => {
                    const position = e.point.data.position;
                    const time = `${e.point.data.timestamp.getHours()}:${e.point.data.timestamp.getMinutes().toString().padStart(2, '0')}`;
                    return {
                        text:
                            `${time} - Position: ${position} Calls: ${e.value}/${e.total} (${(e.percent * 100).toFixed(2)}%)
                                ${positionNames?.get(position) ?? "Unnamed"}`
                    }
                }
                } />
            </Chart>
        </Popup>
    );
}