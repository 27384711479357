import { SelectBox } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import { FunctionComponent, useState } from "react";
import { useToken } from "../../hooks/useToken";
import { Company } from "../../models/company";
import { GetCompaniesAsync } from "../../services/http-service";
import './CompaniesDropdown.css';

const getDefaultCompany = () => JSON.parse(sessionStorage.getItem("company") ?? "null") as (Company | undefined);

export interface CompaniesDropdownProps {
    onSelectedCompanyChanged: (c: Company | undefined) => void;
}

const CompaniesDropdown: FunctionComponent<CompaniesDropdownProps> = ({ onSelectedCompanyChanged }) => {
    const token = useToken();

    const dataStore = new CustomStore({
        loadMode: "raw",
        key: "id",
        load: (o) => {
            if (!token.getToken()) {
                return Promise.resolve([]);
            }

            return GetCompaniesAsync(token.getToken()!);
        }
    });

    const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(getDefaultCompany());

    return (
        <SelectBox
            dataSource={dataStore}
            displayExpr="companyName"
            noDataText="No Companies Available"
            onValueChanged={(e) => {
                setSelectedCompany(e.value as (Company | undefined));
                sessionStorage.setItem("company", JSON.stringify(e.value));
                onSelectedCompanyChanged(e.value);
            }}

            stylingMode="filled"
            className="comp-dropdown"
            defaultValue={selectedCompany}
            onInitialized={(_) => {
                onSelectedCompanyChanged(getDefaultCompany());
            }}
        />
    )
};

export default CompaniesDropdown;