import { FunctionComponent, useEffect, useState } from "react";
import { useToken } from "../../hooks/useToken";
import { GetIsAdminAsync } from "../../services/http-service";

interface IsAdminProps {
    is: JSX.Element;
    isNot?: JSX.Element;
}

/**
 * Wrapper component for rendering specific components when the user is an admin
 * @props is Element to show when the user is an admin.
 * @props isNot Optional element to show the user when the user is not adn admin.
 */
const IsAdmin: FunctionComponent<IsAdminProps> = ({ is, isNot }) => {
    var token = useToken();

    var [isAdmin, setIsAdmin] = useState<boolean | null>(sessionStorage.getItem("__is_admin") as boolean | null);

    useEffect(() => {
        if (token.getToken() === undefined || isAdmin !== null) return;
        GetIsAdminAsync(token.getToken()!)
            .then(isAdmin => {
                setIsAdmin(isAdmin);
                sessionStorage.setItem("__is_admin", isAdmin.toString())
            });
    }, [token, isAdmin]);

    if (!isAdmin) {
        return (
            <>
                {isNot}
            </>
        );
    }

    return (
        <>
            {is}
        </>
    );
};

export default IsAdmin;