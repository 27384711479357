import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { FunctionComponent, useState } from 'react';
import { useToken } from '../../hooks/useToken';

import CompaniesDropdown from '../../components/companies-dropdown/CompaniesDropdown';
import CustomStore from 'devextreme/data/custom_store';
import './TransactionHistory.css';

import Notify from 'devextreme/ui/notify'
import FileSaver from 'file-saver';

import DataGrid, {
  Button, Column
} from 'devextreme-react/data-grid';
import { TransactionDataChart } from './TransactionDataChart';
import { DownloadFileAsync, GetTransactionsAsync } from '../../services/http-service';
import { Company } from '../../models/company';

const TransactionHistory: FunctionComponent = () => {
  let token = useToken();

  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);

  const dataStore = new CustomStore({
    loadMode: "processed",
    key: "id",
    load: (o) => {
      if (!token.getToken()) {
        return Promise.reject();
      }

      if (!selectedCompany) return Promise.resolve([]);
      return GetTransactionsAsync(token.getToken()!, selectedCompany.id);
    }
  });

  let [chartPopupVisible, setChartPopupVisible] = useState<boolean>(false);
  let [chartName, setChartFilename] = useState<string | undefined>(undefined);

  return (
    <div className='camp-table'>
      <AuthenticatedTemplate >
        <TransactionDataChart isVisible={chartPopupVisible} filename={chartName} onClose={(_) => setChartPopupVisible(false)} selectedCompanyId={selectedCompany?.id} />
        <CompaniesDropdown onSelectedCompanyChanged={(c) => setSelectedCompany(c)} />
        <DataGrid
          id='gridContainer'
          showBorders={true}
          dataSource={token && selectedCompany ? dataStore : undefined}
          pager={{ allowedPageSizes: [10, 20, 50], visible: true, displayMode: "adaptive", showPageSizeSelector: true }}
          filterRow={{ visible: true }}
        >
          <Column dataField="timeStamp" dataType="datetime" />
          <Column dataField="instance.instanceName" caption="Instance Name" />
          <Column dataField="sent" width={50} allowSorting={false} />
          < Column dataField="transactionDate" dataType="date" format={"dd/MM/yyyy"} defaultSortIndex={0} defaultSortOrder="desc" />
          <Column type={"buttons"} width={80}>
            <Button hint="Download" icon='download' visible={true} onClick={async (e: any) => {
              const r = await DownloadFileAsync(token.getToken()!, e.row.data.dataFilename)
              if (r.ok) {
                const data = await r.blob();
                FileSaver.saveAs(data, e.row.data.dataFilename)
              } else {
                Notify({
                  message: `${(await r.json()).message}`,
                  type: 'error',
                  displayTime: 2500,
                });
              }
            }} />
            <Button hint="View" icon='chart' visible={true} onClick={async (e: any) => {
              setChartPopupVisible(true);
              setChartFilename(e.row.data.dataFilename);
            }} />
          </Column>
        </DataGrid>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        Please sign-in to see this page.
      </UnauthenticatedTemplate>
    </div >
  );
}

export default TransactionHistory;
